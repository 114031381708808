<template>
  <v-menu v-model="menu" v-bind="combinedMenuProps">
    <template v-slot:activator="{ on }">
      <div class="datepicker" v-on="on">
        <v-text-field v-bind="combinedTextFieldProps" :value="value"></v-text-field>
      </div>
    </template>
    <template v-if="showCalendar">
      <v-date-picker
        v-if="showCalendar"
        v-bind="combinedPickerProps"
        :value="value"
        @input="onInput"
      ></v-date-picker>
    </template>
  </v-menu>
</template>

<script>
import merge from 'lodash/merge';

export default {
  props: {
    value: {
      type: String,
      required: false,
    },
    menuProps: {
      type: Object,
      default: () => ({}),
    },
    textFieldProps: {
      type: Object,
      default: () => ({}),
    },
    pickerProps: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  model: {
    prop: 'value',
    event: 'update-date',
  },
  data: () => ({
    defaultMenuProps: {
      transition: 'scale-transition',
      'max-width': '100%',
    },
    defaultPickerProps: {},
    defaultTextFieldProps: {
      'append-icon': 'mdi-calendar-range',
      class: 'border-label-input',
      'hide-details': true,
      outline: true,
      readonly: true,
    },
    menu: false,
  }),
  computed: {
    showCalendar() {
      return !this.disabled;
    },
    combinedMenuProps() {
      return merge(this.defaultMenuProps, this.menuProps);
    },
    combinedTextFieldProps() {
      return merge(this.defaultTextFieldProps, this.textFieldProps);
    },
    combinedPickerProps() {
      return merge(this.defaultPickerProps, this.pickerProps);
    },
  },
  methods: {
    onInput(date) {
      this.$emit('update-date', date);
      this.menu = false;
    },
  },
};
</script>
