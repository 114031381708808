import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VLayout,
    { attrs: { row: "", wrap: "" } },
    [
      _c(
        VCard,
        { staticClass: "w100" },
        [
          _c(VCardTitle, { attrs: { "primary-title": "" } }, [
            _c("div", { staticClass: "H5-Primary-Left" }, [
              _vm._v("Notification Name")
            ])
          ]),
          _c(
            "div",
            { staticClass: "v-card-content" },
            [
              _c(
                VLayout,
                [
                  _c(
                    VFlex,
                    { attrs: { xs12: "", md7: "" } },
                    [
                      _c(
                        VLayout,
                        { staticClass: "wrap" },
                        [
                          _c(
                            VFlex,
                            { attrs: { xs12: "" } },
                            [
                              _c(VTextField, {
                                attrs: {
                                  label: "Notification Name",
                                  rules: _vm.nameRules
                                },
                                model: {
                                  value: _vm.name,
                                  callback: function($$v) {
                                    _vm.name = $$v
                                  },
                                  expression: "name"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            VFlex,
                            { attrs: { xs12: "" } },
                            [
                              _c(VTextarea, {
                                attrs: {
                                  outline: "",
                                  "auto-grow": "",
                                  label: "Notification Description",
                                  rows: "4",
                                  rules: _vm.descriptionRules,
                                  maxlength: "240",
                                  counter: "240"
                                },
                                model: {
                                  value: _vm.description,
                                  callback: function($$v) {
                                    _vm.description = $$v
                                  },
                                  expression: "description"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }