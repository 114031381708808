import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VMenu,
    _vm._b(
      {
        scopedSlots: _vm._u([
          {
            key: "activator",
            fn: function(ref) {
              var on = ref.on
              return [
                _c(
                  "div",
                  _vm._g({ staticClass: "datepicker" }, on),
                  [
                    _c(
                      VTextField,
                      _vm._b(
                        { attrs: { value: _vm.value } },
                        "v-text-field",
                        _vm.combinedTextFieldProps,
                        false
                      )
                    )
                  ],
                  1
                )
              ]
            }
          }
        ]),
        model: {
          value: _vm.menu,
          callback: function($$v) {
            _vm.menu = $$v
          },
          expression: "menu"
        }
      },
      "v-menu",
      _vm.combinedMenuProps,
      false
    ),
    [
      _vm.showCalendar
        ? [
            _vm.showCalendar
              ? _c(
                  VDatePicker,
                  _vm._b(
                    { attrs: { value: _vm.value }, on: { input: _vm.onInput } },
                    "v-date-picker",
                    _vm.combinedPickerProps,
                    false
                  )
                )
              : _vm._e()
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }