<template>
  <v-layout row wrap mt-4 mb-4>
    <v-card class="w100">
      <v-card-title primary-title>
        <div class="H5-Primary-Left display-flex w100 justify-space-between">Send Schedule</div>
      </v-card-title>
      <div class="v-card-content">
        <v-layout>
          <v-flex xs12>
            <v-layout class="wrap">
              <v-flex xs5>
                <div class="H6-Secondary-Left mb-3">Select Date</div>
              </v-flex>
              <v-flex xs12>
                <v-layout class="wrap">
                  <v-flex xs3 mr-3>
                    <date-picker
                      :disabled="disabledValue"
                      :text-field-props="{
                        'hide-details': false,
                        label: 'Date',
                        disabled: disabledValue,
                      }"
                      :picker-props="{
                        min: `${setMinDate(notification.scheduled_release_date)}`,
                        disabled: disabledValue,
                      }"
                      :value="setInitialDate(notification.scheduled_release_date)"
                      @update-date="onPropChange('scheduled_release_date', $event)"
                    ></date-picker>
                  </v-flex>
                  <v-flex xs3> </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12>
            <v-layout class="wrap">
              <v-flex xs5>
                <div class="H6-Secondary-Left mb-3">Select Times</div>
              </v-flex>
              <v-flex xs12>
                <v-layout class="wrap">
                  <v-flex xs3 mr-3>
                    <time-select
                      :label="'Start Time'"
                      v-bind:value.sync="notification.scheduled_release_time"
                      :rules="timeRules(this.notification, disabledValue)"
                      style="width: 100%"
                      autocomplete
                      :disabled="disabledValue"
                    >
                    </time-select>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </div>
    </v-card>
  </v-layout>
</template>
<script>
import datePicker from '@/components/datePicker';
import timeSelect from '@/components/timeSelect';
import { DateTime } from 'luxon';
import rules from '@/rules';

export default {
  components: {
    datePicker,
    timeSelect,
  },
  props: {
    notification: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    today: DateTime.local().toISODate(),
    dateRules: [rules.required('Schedule start date cannot be empty.')],
    timeRules: (notification, override) => [
      rules.required('Schedule start time cannot be empty.'),
      rules.allowedTime(
        notification.scheduled_release_time,
        notification.scheduled_release_date,
        override,
        'You cannot schedule a notification to be released in the past',
      ),
    ],
    disabledValue: false,
  }),
  methods: {
    onPropChange(k, v) {
      this.$emit('update:notification', {
        ...this.notification,
        [k]: v,
      });
    },
    setMinDate(scheduled_release_date) {
      return this.checkStatus() ? scheduled_release_date : this.today;
    },
    checkStatus() {
      return this.notification.status || this.checkReleaseDates();
    },
    checkReleaseDates() {
      const parsed_today = new Date();
      const notification_release_date_parsed = this.notification.scheduled_release_date
        ? new Date(
            `${this.notification.scheduled_release_date}T${this.notification.scheduled_release_time}`,
          )
        : new Date(`${this.notification.date_created}T${this.notification.time_created}`);
      const comparer = parsed_today >= notification_release_date_parsed;
      return comparer;
    },
    setInitialDate(notificationValue) {
      if (notificationValue === '') {
        this.notification.scheduled_release_date = this.today;
        this.$emit('update:notification', {
          ...this.notification,
          scheduled_release_date: this.today,
        });
        return this.today;
      }
      return notificationValue;
    },
  },
  mounted() {
    this.disabledValue = this.notification.id ? this.checkStatus() : false;
  },
};
</script>
<style></style>
